import customTheme from "./customTheme"

const appicalTheme = {
    // -- SPACING
    spacing: {
        extraSmall: "4px",
        gap: "8px",
        smallest: "16px",
        small: "24px",
        medium: "32px",
        large: "40px",
        largest: "48px",
        max: "56px",
    },
    sizes: {
        iconButtonSize: "32px",
        sideMenu: "256px",
        sideBar: {
            default: "400px",
            MD: "360px",
            SM: "300px",
            XS: "100%",
        },
        mainContent: {
            default: "720px",
            MD: "540px",
            SM: "350px",
            XS: "100%",
        },
        welcomeImageHeight: "384px",
        welcomeImageHeightMobile: "192px",
        knowledgeBaseCoverImageHeightMobile: "133px",
        tabBarHeight: "48px",
        appBarHeight: "56px",
        userProfileImageSize: 60,
        pagesTopPadding: "40px",
        pagesVideoHeight: "100%", // old height 405px
        moreInfoWidth: "426px",
        courseCardWidth: "135px",
        courseCardHeight: "135px",
        storyCardWidth: "344px",
        storyImageHeight: "230px",
        chapterImageHeight: "172px",
        chapterCardWidth: "256px",
        pagesMaxWidth: "1024px",
        navigationBarHeight: "72px",
        pullOutListWidth: "664px",
        courseSelector: "960px",
        courseSelectorDrawer: "327px",
    },
    borderRadius: "8px",
    iconBorderRadius: "12px",

    // -- BASIC
    primary: "rgba(64, 74, 88, 1)",
    contrast: "rgba(255, 108, 92, 1)",
    contrastOnWhite: null,

    // -- ADVANCED
    primaryDark: null,
    primaryLight: null,
    primaryText: null,
    primaryBlue: "#006aff",
    primaryBlack: "#111111",

    contrastDark: null,
    contrastLight: null,
    contrastText: null,

    mainBg: null,

    success: "rgba(34, 129, 90, 1)",
    successText: null,
    successDark: null,
    error: "rgba(253, 112, 96, 1)",
    errorText: null,
    score: "rgba(245, 166, 35, 1)",
    scoreText: null,
    warning: "#DD0007",

    checkmark: "rgba(34, 129, 90, 1)",
    white: "rgba(255, 255, 255, 1)",
    white10: "rgba(255, 255, 255, 0.1)",
    white35: "rgba(255, 255, 255, 0.35)",
    black: "rgba(17, 17, 17, 1)",
    black4: "rgba(17, 17, 17, 0.04)",
    black10: "rgba(17, 17, 17, 0.1)",
    black20: "rgba(17, 17, 17, 0.2)",
    black35: "rgba(17, 17, 17, 0.35)",
    black30: "rgba(17, 17, 17, 0.30)",
    black70: "rgba(17, 17, 17, 0.7)",
    gray: "#a4a4a4",
    inactive: "#6B6B6B",
    placeholder: "#757575",


    // SOCIAL
    facebookColor: "#3B5998",
    instagramColor: "#833AB4",
    linkedInColor: "#0077B5",
    twitterColor: "#1DA1F2",
    skypeColor: "#00AFF0",

    // -- FONTS
    titleText: {
        color: null,
        fontName: null,
        url: null,
        css: null,
    },
    bodyText: {
        color: null,
        fontName: null,
        url: null,
        css: null,
    },
    answerText: {
        color: null,
        fontName: null,
        url: null,
        css: null,
    },

    // -- DASHBOARD
    welcomeBg: null,
    welcomeBgText: null,
    checklistBg: null,

    // -- PAGES
    bottomNavBg: null,

    // -- LOGIN
    useLoginGradient: true,

    loginBackgroundImage: null,
    loginBackgroundImageOpacity: "0.65",

    loginBg1: null,
    loginBg2: null,
    loginBgText: null,
    loginButton: null,
    loginButtonText: null,
    loginButtonDark: null,

    resetPasswordBg1: null,
    resetPasswordBg2: null,
    resetPasswordBgText: null,
    resetPasswordButton: null,
    resetPasswordButtonText: null,
    resetPasswordButtonDark: null,

    linkColor: "rgba(0, 106, 255, 1)",
    labelColor: "#878787",
    inputBackgroundColor: "#f2f7ff",
    inputBorderColor: "#d9e8ff",


    // leaderboard
    leaderboard: {

    },

    ...customTheme(),
}

export default appicalTheme
