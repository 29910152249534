import { withRouter } from "react-router";
import connect from "./connectToStore";
import useSnowplow from "./hooks/useSnowplow";


/**
 * Component to integrate the Snowplow tracker into the application.
 * 
 * This component uses the `useSnowplow` custom hook to initialize and set up the Snowplow tracker.
 * It ensures that all necessary context data (user, course, organization, etc.) is passed to the tracker.
 * 
 * @param {object} props - The props passed to the component.
 * @param {object} props.user - The user object from the Redux Store.
 * @param {string} props.userRole - The role of the user from the Redux Store.
 * @param {object} props.course - The course object from the Redux Store.
 * @param {object} props.organisation - The organisation object from the Redux Store.
 * @param {object} props.currentPage - The current page object from the Redux Store.
 * @param {object} props.story - The current story object from the Redux Store.
 * @param {object} props.chapter - The current chapter object from the Redux Store.
 * @param {object} props.location - The location object from the router, used to extract the chapter ID.
 * @param {function} props.loadUser - The action to load user data from the Redux Store.
 * 
 * @returns {null} This component does not render anything on the UI.
 */
const SnowplowTracker = (props) => {
  useSnowplow(props);

  return null;
};

export default withRouter(connect(SnowplowTracker));
