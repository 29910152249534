import { useEffect, useState } from "react";
import { ApSnowplow } from "@appical/snowplow-tracker";
import { constants } from "../../constants";


/**
 * Custom hook for initializing and setting up the Snowplow tracker.
 * 
 * This hook sets up the Snowplow tracker to track user behavior on the platform, including link clicks, form submissions,
 * and page views. It uses data from various sources, including user information, course details, and organization details,
 * to populate the context sent to the tracker.
 * 
 * @param {object} params - The parameters required for Snowplow tracking.
 * @param {object} params.user - The user object containing user-related information.
 * @param {string} params.userRole - The role of the user (e.g., "admin", "student").
 * @param {object} params.course - The course object containing course-related information.
 * @param {object} params.organisation - The organisation object containing organization-related information.
 * @param {object} params.currentPage - The current page object containing information about the current page.
 * @param {object} params.story - The story object containing information about the current story.
 * @param {object} params.chapter - The chapter object containing information about the current chapter.
 * @param {object} params.location - The location object from the router, used to extract the chapter ID.
 * @param {function} params.loadUser - The action to load user data from the Redux Store.
 */
const useSnowplow = ({
    user,
    userRole,
    course,
    organisation,
    currentPage,
    story,
    chapter,
    location,
    loadUser
}) => {
    const [userData, setUserData] = useState(null);
    const [orgData, setOrgData] = useState(null);
    const [pageData, setPageData] = useState({});

    /**
     * Retrieves the user role based on the role key from constants.
     * 
     * @param {string} role - The role key from the Redux Store.
     * @returns {string} The user role as a string.
     */
    const getUserRole = (role) => {
        const userRoles = constants.userRoles;
        return Object.keys(userRoles).find(key => userRoles[key] === role);
    };

    useEffect(() => {
        // Load user data if not already loaded, but only if there is course data available
        if (!user && course && course.id) {
            loadUser(course.id);
        }
    }, [user])

    /**
    * Retrieves the page type based on the pageType key from constants.
    * 
    * @param {string} pageType - The pageType key from the Redux Store.
    * @returns {string} The page type as a string.
    */
    const getPageType = (pageType) => {
        const pageTypes = constants.pageTypes;
        return Object.keys(pageTypes).find(key => pageTypes[key] === pageType);
    };

    const { pathname } = location;
    const pathParts = pathname.split("/");
    const chapterId = pathParts[6];

    useEffect(() => {
        // Update user and organization data when available
        if (user && userData !== user) {
            setUserData(user);
        }

        // Update organization data when available
        if (organisation && Object.keys(organisation).length > 0 && orgData !== organisation) {
            setOrgData(organisation);
        }
    }, [user, organisation]);
    
    useEffect(() => {
        // Store the page data for comparison
        if (currentPage && pageData !== currentPage) {
            // Check if the current page has changed
            if (currentPage?.page?.id !== pageData?.page?.id) {
                setPageData(currentPage);
            }
        }
    }, [currentPage])

    useEffect(() => {
        // Check if all necessary data is available before initializing Snowplow
        if (!userData || !orgData || !course) {
            return;
        }

        /**
         * Context about the customer, providing information related to the organization.
         * @type {object}
         */
        const context_customer = {
            customer_name: organisation?.name,
            company_name: organisation?.name,
            organization_no: organisation?.id?.toString() || "",
            business_unit: "ADD",
            countryiso: "XX",
        };

        /**
         * Context about the product, providing details related to the course and chapters.
         * @type {object}
         */
        const context_product = {
            r12: "004",
            product_name: "Appical - Webplayer",
            module_name: course?.title,
            module_no: "",
            customfield_product1: story?.title || '',
            customfield_product2: chapter?.title || '',
            customfield_product3: chapterId && getPageType(currentPage?.page.pageType) || ''
        };

        /**
         * Context about the user, providing details such as the user role and registration date.
         * @type {object}
         */
        const context_user = {
            user_type: getUserRole(userRole),
            registration_date: userData?.first_day,
            org_name: organisation?.name,
            org_ID: organisation?.id?.toString() || "",
        };

        if (userData && orgData && course) {
            try {
                const apSnowplow = ApSnowplow.getInstance({
                    appId: 'Appical_Now',
                    environment: process.env.REACT_APP_ENV || "dev",
                });
                apSnowplow.updateConfig({
                    userData,
                    contextCustomerData: context_customer,
                    contextProductData: context_product,
                    contextUserData: context_user
                })
                apSnowplow.setIsActivityTrackingEnabled(true)
                apSnowplow.setIsLinkClickTrackingEnabled(true)
                apSnowplow.setIsFormTrackingEnabled(true)
                apSnowplow.setIsPageViewTrackingEnabled(true)
                apSnowplow.init();
                console.log('ApSnowplow initialized');
            } catch (error) {
                // use console.log for debugging on local environment
                console.log("Error initializing Snowplow:", error);
                console.error("Error initializing Snowplow:", error);
            }

        }

    }, [course, story, chapter, pageData, userData, orgData]);


};

export default useSnowplow;
